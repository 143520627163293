* {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  color: #0b1b34;
  /* background-color: #0d346d; */
}

body {
  width: 100%;
}

body .header {
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  flex: 1;
  padding-bottom: 10px;
  padding-top: 10px;
  max-height: 200px;
  background-color: #0d346d;
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 100px;
}

.image-button-container {
  display: block;
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 0;
  background-image: url('../src/images/barge4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 99999px rgba(16, 88, 170, 0.9);
}

.image-button-container .desc {
  position: relative;
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 1%;
  background-color: transparent;
  color: white;
  font-weight: bolder;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.image-button-container .contact-buttons {
  position: relative;
  top: 15%;
}

.image-button-container .contact-buttons .call {
  position: absolute;
  bottom: 25%;
  right: 57%;
  width: 15%;
  background-color: #0d346d;
  color: #fafafa;
  border-radius: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1%;
  text-align: center;
  border-bottom: 1px solid #ebebeb;
}

.image-button-container .contact-buttons .email {
  position: absolute;
  bottom: 25%;
  right: 27%;
  width: 15%;
  background-color: #0d346d;
  color: #fafafa;
  border-radius: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1%;
  text-align: center;
  border-bottom: 1px solid #ebebeb;
}

.barge-container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.3);
}

.barge {
  height: 100%;
  border-radius: 2px;
  object-fit: cover;
  z-index: -1;
}

.back-to-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-big {
  height: 70%;
  max-width: 600px;
}

.coming {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.home-logo {
  height: 70%;
  max-width: 150px;
}

/* squares part of page */

.squares-container {
  display: grid;
  /* height: 75vh; */
  /* added responsiveness */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* grid-template-columns: 50% 50%; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: none;
}

.square1 {
  display: grid;
  height: 400px;
  border: 0.5px solid #ebebeb;
  background-color: #fafafa;
}

.sqaure2 {
  display: grid;
  height: 400px;
}

.services-p {
  order: -1;
}

.oyster-p {
  order: -1;
}

.demolition-p {
  order: -1;
}

.main-blurb {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: bold;
  text-align: center;
  width: 60%;
  margin-bottom: 40px;
  margin-top: 20px;
}

.picture {
  height: 100%;
  object-fit: cover;
}

.quick-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #0d346d;
}

.quick-links-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  /* margin-top: 7px; */
  background-color: #0d346d;
}

.quick-links-1 a,
.quick-links-1 p {
  margin-top: 1em;
  align-items: center;
  justify-content: center;
  background-color: #0d346d;
  color: #fafafa;
  font-weight: bold;
}

a {
  text-decoration: none;
  text-align: center;
}

.blurbs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blurbs-container h3 {
  text-align: center;
  margin-bottom: 20px;
}

.blurbs-container p {
  width: 90%;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  text-align: left;
}

.blurbs-container .services-blurb-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 16px;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  width: 50%;
  font-size: 1.2em;
}

.footer-logo {
  width: 60px;
  z-index: 1;
  background-color: transparent;
}

.footer {
  height: 100px;
  padding-top: 15px;
  background-color: #0d346d;
  /* border-top: 1px solid #ebebeb; */
}

.learn-more {
  color: #fafafa;
  font-weight: bolder;
  font-size: 1.3em;
  background-color: rgb(192, 5, 5);
  height: 2.5em;
  width: 20%;
  margin-top: 3%;
  /* padding-top: 2%; */
  border-radius: 10px;
  text-align: center;
  line-height: 2.5em;
  vertical-align: center;
}

ul {
  font-size: 1.2em;
}

p {
  font-size: 1.2em;
}

.container-email {
  font-size: 1.2em;
}

h3 {
  font-size: 3em;
  color: #0d346d;
}

.picture {
  height: 400px;
}

/* services page  */

.barge-services {
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.desc-services {
  position: absolute;
  bottom: 40%;
  right: 0%;
  width: 100%;
  background-color: transparent;
  color: #fafafa;
  font-weight: bolder;
  font-size: 3em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-kerning: auto;
}

.image-button-container-services {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url('../src/images/barge4.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 99999px rgba(16, 88, 170, 0.9);
  z-index: 2;
  /* line-height: 250px; */
}

/* banner */
.banner {
  /* display: flex;
  flex-direction: row; */
  /* width: 100%; */
  /* flex-wrap: wrap; */
  /* align-items: center; */
  font-weight: bold;
  text-align: right;
  height: fit-content;
  line-height: 2.5em;
  padding-right: 0.33%;
  font-size: 0.8em;
}

.banner,
.banner a {
  background-color: #fafafa;
  color: #0d346d;
}

.banner a {
  padding-right: 5px;
  padding-left: 5px;
}

.gallery-1 {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.gallery-1 h3 {
  text-align: center;
}

.gallery-1 p {
  font-size: 20px;
  width: 70%;
}

.services-container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.gallery-1 .barge-margin {
  margin-bottom: 100px;
  margin-left: 0;
}

@media only screen and (max-width: 768px) {
  .top-links {
    display: none;
  }

  .services-p {
    order: 1;
  }

  .oyster-p {
    order: 1;
  }

  .demolition-p {
    order: 1;
  }

  .gallery-1 {
    flex-direction: column;
  }

  .image-button-container .desc {
    font-size: 2em;
    overflow: scroll;
  }

  .banner {
    padding: 0px;
  }

  .banner,
  a {
    padding: 0px;
  }

  .services-container {
    width: 90vw;
  }

  .gallery-1 .barge-margin {
    margin-bottom: 0px;
    margin-left: 0;
    margin-top: 1em;
  }

  .gallery-1 .evo {
    margin-bottom: 0px;
    margin-left: 0;
    margin-top: 0.25em;
  }
  /* .image-button-container .call {
    display: none;
  }

  .image-button-container .email {
    display: none;
  } */
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 40px;
  background-color: transparent;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #b1232a;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #e05057;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  /* background: #373a47; */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  /* color: #f8f7e7; */
  padding: 0.8em;
  background-color: #fafafa;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.8em;
  color: #0b1b34;
  font-weight: bold;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.header a {
  background-color: #0d346d;
}

.gallery-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.carousel {
  width: 400px;
  margin: 20px;
  margin-bottom: 40px;
}

.gallery-wrapper .footer {
  border-top: 1px solid #ebebeb;
}

.about-page p {
  font-size: 16px;
}

.equipment-desc {
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  text-align: left;
  margin-bottom: 10px;
  /* margin-left: 50px; */
}

.equipment-desc1 {
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  text-align: left;
  margin-bottom: 100px;
  margin-left: 25px;
}

.equipment-desc li {
  text-align: left;
}

.equipment-desc1 li {
  text-align: left;
}

.services-desc {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 16px;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
}

.services-desc li:hover {
  color: navy;
}

.services-desc .detail {
  font-size: 12px;
}

.marin {
  width: 100px;
  height: 39.5px;
}

.blurbs-container span {
  width: 90%;
  text-align: left;
}

.services-header {
  color: #b1232a;
  text-align: center;
  line-height: 100px;
  margin-bottom: 0;
  /* margin-top: 15px; */
  text-decoration: underline;
}

.phone {
  max-height: 500px;
  min-height: 200px;
}

.about-page sup {
  font-size: xx-small;
  vertical-align: top;
  /* font-size: smaller; */
}
